import React from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo"

const qrCode = () => {
    return (
        <Layout withMenu footer={false}>
            <SEO title="QR Code"  description='QR Code'/>
        </Layout>
    );
};

export default qrCode;